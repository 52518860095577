/* HEALTH */
.ofb_health_tab {
    border:1px solid black;
}
.ofb_health_tab_hidden {
    display:none;
}


/* GENERAL */
.ofb_red {
    background:#f44336;
    color:white;
}

.ofb_red_text {
    color:red;
}
.ofb_black {
    background:black;
    color:white;
}
.ofb_dark_green {
    color: #005d00;
}
.ofb_bold {
    font-weight:bold;
}
.ofb_border_top {
    border-top: 1px solid black;
}
.ofb_button {
    /*background:none;*/
    border:none;
    cursor:pointer;
    padding:10px;
}
.ofb_todo_header_button {
    border:1px solid #656565;
    background: #e1e1e1;
    cursor:pointer;
    padding:10px;
    width:100%;
}
.ofb_table {
    border:1px solid rgba(178, 178, 178, 0.7);
    border-collapse: collapse;
    width:100%;
}
.ofb_table tr {
    border:1px solid rgba(178, 178, 178, 0.7);
    cursor:pointer;
}
.ofb_table_main_row:hover {
    background: #cbcbcb;
}
.ofb_table td {
    padding:5px;
}
.ofb_hide {
    display:none;
}
.ofb_error_message_div {
    color:red;
    padding:10px;
}
.ofb_pointer {
    cursor:pointer;
}
.ofb_modal_body {
    padding:20px;
    font-size:18pt;
}
.ofb_button:hover {
    background:rgba(0,0,0,0.6);
}
.ofb_button:focus {
    border:1px solid black;
}
.ofb_check {
    padding:8px;
    display:inline-block;
    border:none;
    border-bottom:1px solid #ccc;
    width:24px;
    height:24px;
    position:relative;
    margin: 0 5px 0 5px;
}
.ofb_label {
    font-weight:bold;
    flex:0 0 auto;
    padding:5px 5px 5px 5px;
    align-items: center;
    height:40px;
    vertical-align: middle;
    justify-content: end;
    display:flex;
    width:20%;
}
.ofb_select_input {
    padding:8px;
    width:100%;
    border:none;
    border-bottom:1px solid #ccc;
    background:white;
}
.ofb_text_input {
    padding:8px;
    display:block;
    border:none;
    border-bottom:1px solid #ccc;
    width:100%;
}
.ofb_datetime_input {
    display:block;
    border:none;
    border-bottom: 1px solid #ccc;
    width:100%;
}
.ofb_input_holder {
    display:flex;
    flex-direction:row;
    flex: 0 1 auto;
}
.ofb_search_box {
    padding:8px;
    display:block;
    border:1px solid #ccc;
    width:100%;
    margin:2px;
}

/* FINANCE */
.ofb_finance_app {
    width:100%;
}

.ofb_finance_transaction {
    width:100%;
    border:1px solid #ccc;
    margin-bottom:15px;
    box-shadow:3px 3px #ababab;
    cursor:pointer;
}
.ofb_finance_new_transaction_row1 {
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-items: stretch;
    width:100%;
}
@media only screen and (min-width: 750px) {
    .ofb_finance_new_transaction_row1 {
        width:200px;
    }
}
.ofb_finance_payee_input {
    border:none;
    border-right:1px solid black;
    width:340px;
}
.ofb_finance_transaction_date {
    width:140px;
    border:none;
    border-right:1px solid #ccc;
}
.ofb_finance_reconciled_input {
    width:40px;
    border:none;
    border-right:1px solid #ccc;
}
.ofb_finance_payment_input {
    width:80px;
    border:none;
    border-right:1px solid black;
    text-align:right;
}
.ofb_finance_deposit_input {
    width:80px;
    border:none;
    border-right:1px solid black;
}
.ofb_finance_balance_input {
    width:80px;
    border:none;
}

/* FINANCE TRANSACTION DISPLAY */
.ofb_transaction {
    border-style:solid;
    border-width: 1px 1px 0px 1px;
    padding:5px;
    margin-bottom:5px;
}
.ofb_transaction_part {
    border-width:0 1px 1px 1px;
    border-style:solid;
    padding:5px;
    display:flex;
    flex-direction:row;
    flex-wrap: nowrap;
}
.ofb_finance_transaction_row {
    display:flex;
    flex-direction:row;
    border:1px solid #ccc;
    width:100%;
}
.ofb_finance_transaction_detail_row {
    display:flex;
    flex-direction:row;
    border:1px solid #ccc;
    width:100%;
}
.ofb_finance_transaction_hidden_detail_row {
    display:flex;
    flex-direction:column;
    border:1px solid #ccc;
    width:100%;
}
.ofb_transaction_split_holder_row {
    display:flex;
    flex-direction:column;
    border:1px solid #ccc;
    width:100%;
}
.ofb_transaction_split_row {
    display:flex;
    flex-direction:row;
    width:100%;
    font-size:9pt;
}
.ofb_transaction_split_category {
    margin-left:20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex:1;
    text-align: start;
}
.ofb_transaction_split_amount {
    width:80px;
    text-align:end;
    margin-right:20px;
}
.ofb_split_text {
    color:red;
    font-size:9pt;
}
.ofb_transaction_date_cell {
    flex: 0 0 40px;
    padding:2px;
    min-width:40px;
    font-size:10pt;
}

.ofb_transaction_payee_cell {
    border:none;
    text-align:left;
    padding:1px;
    min-width:130px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex:1;
}
.ofb_transaction_category_cell {
    border:none;
    border-right:1px solid #ccc;
    text-align:left;
    padding:1px;
    max-width:130px;
    min-width:130px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.ofb_finance_split_button {
    height:25px;
    font-size:10pt;
    border:none;
    background: #e0e0e0;
    cursor:pointer;
}
.ofb_finance_split_button:hover {
    background:#ccc;
}
.ofb_transaction_memo_cell {
    display:none;
}
@media only screen and (min-width: 750px) {
    .ofb_transaction_date_cell {
        width:60px;
        flex:0 0 60px;
    }
    .ofb_transaction_payee_cell {
        min-width:650px;
    }
    .ofb_transaction_category_cell {
        width:450px;
        max-width:450px;
        min-width:450px;
    }
    .ofb_transaction_memo_cell {
        display:block;
        border:none;
        text-align:left;
        padding:1px;
        width:50px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex-grow:1;
        flex-shrink:0;
        font-size:10pt;
    }
}
.ofb_transaction_reconciled_cell {
    border:none;
    border-right:1px solid #ccc;
    width:30px;
    padding:1px;
    text-align:center;
    flex-grow:0;
    flex-shrink:0;
    color: #6b6b6b;
}
.ofb_transaction_parent_balance_cell {
    border:none;
    border-right:1px solid #ccc;
    width:60px;
    padding:1px;
    text-align:right;
    flex-grow:1;
    flex-shrink:0;
    font-size:6pt;
    color:blue;
    display:flex;
    justify-content:center;
    align-items:center;
}
.ofb_transaction_amount_cell {
    border:none;
    border-right:1px solid #ccc;
    width:60px;
    padding:1px;
    text-align:right;
    flex-grow:1;
    flex-shrink:0;
    font-size:10pt;
}
.ofb_transaction_balance_cell {
    border:none;
    width:60px;
    padding:1px;
    text-align:right;
    flex-grow:1;
    flex-shrink:0;
    font-size:10pt;
}
.ofb_finance_net_worth {
    font-size:16pt;
    margin:20px;
}
.ofb_finance_accounts_table {
    border: 2px solid black;
    padding: 0;
    width:100%;
    text-align:left;
    border-collapse: collapse;
}
.ofb_finance_account_balance {
    padding:5px;
    width:100%;
    text-align:right;
}
.ofb_finance_account_type_cell {
    padding:5px;
    width:100%;
    text-align:right;
}
.ofb_finance_account_name_div {
    padding:5px;
    cursor:pointer;
    font-size:18pt;
    text-align: right;
    width:100%;
}
.ofb_finance_account_name_div:hover {
    background: #e1e1e1;
}
.ofb_finance_subaccount_name {
    cursor:pointer;
    margin-left:10px;
}
.ofb_finance_subaccount_name:hover {
    background: #e1e1e1;
}
.ofb_finance_account_holder {
    border:1px solid black;
    box-shadow: #a8a8a8 4px 4px;
    margin:10px;
    padding:10px;
    width:100%;
    display:flex;
    flex-direction: row;
}
.ofb_finance_account_holder_left {
    display: flex;
    flex-direction: column;
    width:40%;
    padding-right:10px;
}
.ofb_finance_account_holder_right {
    display: flex;
    flex-direction: column;
    width:50%;
    padding-left:30px;
    text-align:left;
}
.ofb_finance_subaccount_label {
    font-weight:bold;
    margin-top:10px;
}
.ofb_transaction {
    border-style:solid;
    border-width: 1px 1px 0px 1px;
    padding:5px;
}
.ofb_transaction_part {
    border-width:0 1px 1px 1px;
    border-style:solid;
    padding:5px;
    display:flex;
    flex-direction:row;
    flex-wrap: nowrap;
}
.ofb_payee_combobox {
    width:100%;
    border-bottom:1px solid #ccc;
}
.ofb_currency_input_holder {
    width:100%;
    border-bottom:1px solid #ccc;
    padding:3px;
    display:flex;
    flex-direction:row;
}
.ofb_dollar_sign {
    margin-right:3px;
    margin-top:5px;
}
.ofb_currency_input {
    width:100%;
    border:none;
    padding:5px 5px 5px 0;
}
.ofb_tpart_trash_button {
    border:0;
    background:none;
    cursor:pointer;
    padding:2px;
}
.ofb_tpart_trash_button:hover {
    background:#ccc;
}
.ofb_new_transaction_form {
    padding:10px;
}
.ofb_finance_giving_report {
    margin: 20px 0 20px 0;
    border:1px solid black;
    width:250px;
}
.ofb_finance_home_holder {
    display:flex;
    flex-direction:column;
    align-items:center;
}
.ofb_finance_category_report_holder {
    border:1px solid #868686;
    padding:2px;
    box-shadow: #757575 4px 4px;
    margin-bottom:25px;
}
.ofb_finance_category_report {
    display:flex;
    flex-direction: row;
    width:100%;
    justify-content: center;
}
.ofb_finance_category_report div {
    padding-left:20px;
    text-align: end;
    width:160px;
}
@media only screen and (min-width: 750px) {
    .ofb_finance_category_report div {
        width:350px;
    }
}
.ofb_shaded {
    background: #ececec;
}
.ofb_budget_report_date_limiter_holder {
    display:flex;
    justify-content: space-evenly;
    flex-direction: column;
}
@media only screen and (min-width: 750px) {
    .ofb_budget_report_date_limiter_holder {
        flex-direction: row;
    }
}
.ofb_finance_budget_cell {
    cursor:pointer;
}
.ofb_finance_budget_cell:hover {
    background: #bdbdbd;
}
.ofb_finance_budget_edit_holder {
    border:1px solid #9d9d9d;
    display:flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
}
.ofb_finance_budget_edit_row {
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
}
.ofb_finance_budget_edit_amount {
    padding:0 5px 0 5px;
    width:100px;
}
.ofb_finance_budget_edit_dayofmonth {
    padding:0 5px 0 5px;
    width:70px;
    font-size:10pt;
}
.ofb_finance_budget_edit_payee {
    padding:0 5px 0 5px;
    width:180px;
}
@media only screen and (min-width: 750px) {
    .ofb_finance_budget_edit_payee {
        width:300px;
    }
}
.ofb_delete_budget_row_button {
    width:20px;
    height:100%;
    border:0;
    background:none;
    cursor:pointer;
    padding:0;
}
.ofb_budget_form_row {
    display:flex;
    flex-direction:row;
}
.ofb_budget_input {
    width:100%;
    border:1px solid black;
}
.ofb_delete_budget_row_button:hover {
    background: #b7b7b7;
}
.ofb_delete_budget_row_button img {
    width:20px;
    height:20px;
}
.ofb_finance_category_report_selector_div {
    cursor:pointer;
}
.ofb_finance_category_report_selector_div:hover {
    background:#b7b7b7;
}
.ofb_finance_static_display_transaction {
    display:flex;
    flex-direction: row;
    padding:5px;
    border:1px solid #d0d0d0;
    margin:3px;
}
.ofb_finance_static_display_transaction div {
    padding:5px;
}
.ofb_line_below td {
    border-bottom:1px solid black;
}
.ofb_net_worth_report_holder {
    display:flex;
}
.ofb_net_worth_report {
    display:flex;
    flex-direction:column;
}
.ofb_net_worth_report_row {
    display:flex;
    flex-direction:row;
}
.ofb_net_worth_columns {
    display:flex;
    flex-direction:column;
}
.ofb_net_worth_cell {
    display:flex;
    flex-direction:row;
    justify-content: end;
    width:100px;
    padding: 0 5px 0 15px;
    text-align:right;
    border:1px solid #adadad;
}
.ofb_modal_hidden {z-index:3;display:none;padding-top:100px;position:fixed;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:rgb(0,0,0);background-color:rgba(0,0,0,0.4)}
.ofb_modal_shown {z-index:3;display:block;padding-top:100px;position:fixed;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:rgb(0,0,0);background-color:rgba(0,0,0,0.4)}
.ofb_modal_content{margin:auto;background-color:#fff;position:relative;padding:0;outline:0;max-width:350px;}

/* LIBRARY */
.ofb_library_rating_5 {
    color:blue;
}
.ofb_library_rating_4 {
    color:green;
}
.ofb_library_rating_3 {
    color:orange;
}
.ofb_library_rating_2 {
    color:red;
}
.ofb_library_rating_1 {
    color:red;
}
.ofb_library_rating {
    text-align:right;
    min-width:110px;
    display:inline-block;
}
.ofb_library_rating_system {
    font-size:10pt; 
    margin-left:25px;
    display:flex;
    flex-direction:column;
    align-items: start;
    align-self:center;
}
.ofb_library_rating_system_holder {
    display:flex;
    justify-content:center;
    flex-direction:column;
    width:50%;
}
.ofb_library_detail_row {
    display:flex;
    flex-direction:row;
}
.ofb_library_details_table_row {
    /*background: #d7d7d7;*/
    border:2px solid black;
}
.ofb_library_detail_label_cell {
    font-weight:bold;
    padding-right:20px;
    text-align:right;
    min-width:150px;
}
.ofb_library_rating_cell {
    min-width:110px;
}
.ofb_library_add_new_container {
    width:100%;
    padding:40px;
    display:flex;
    flex-direction:column;
}
.ofb_orange {
    background:orange;
}
.ofb_library_want_to_read {
    background: rgba(0, 0, 176, 0.11);
}
.ofb_library_header {
    display:flex;
    flex-direction:row;
}
.ofb_library_stats {
    padding:20px;
}

/* BIBLE MEMORY */
.ofb_bible_memory_key {
    font-size:12pt;
    width:36px;
    height:36px;
    border:1px solid black;
    box-shadow: #3a3a3a 3px 3px;
    background: #eaeaea;
    cursor:pointer;
    margin:2px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
.ofb_bible_memory_key:hover {
    background: #bebebe;
}
.ofb_bible_memory_space_bar {
    width:185px;
    font-size:12pt;
    height:36px;
    border:1px solid black;
    box-shadow: #3a3a3a 3px 3px;
    background: #eaeaea;
    cursor:pointer;
    margin:2px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

}
.ofb_bible_memory_space_bar:hover {
    background: #bebebe;
}
.ofb_bible_memory_greek_keyboard {
    display:flex;
    flex-direction:column;
}
.ofb_bible_memory_greek_row {
    display:flex;
    flex-direction:row;
    justify-content: center;
}
.ofb_bible_memory_latin {
    font-size:9pt;
    color:red;
}
.ofb_bible_memory_pressed_key {
    background: #c4c4c4;
}
.ofb_bible_memory_display {
    font-size:14pt;
    overflow: auto;
}
.ofb_bible_memory_error_display {
    color:red;
    font-size:12pt;
    font-weight:bold;
}
@media only screen and (min-width: 750px) {
    .ofb_bible_memory_key {
        font-size:18pt;
        width:80px;
        height:48px;
    }
    .ofb_bible_memory_space_bar {
        font-size:18pt;
        width:180px;
        height:48px;
    }
    .ofb_bible_memory_error_display {
        font-size:14pt;
    }
    .ofb_bible_memory_display {
        font-size:24pt;
    }
}
.ofb_bible_game_selector {
    display:flex;
    flex-direction:row;
    justify-content: center;
}
.ofb_bible_game_selector div {
    padding-right:20px;
    margin:5px;
}
.ofb_bible_game_selector input {
    width:30px;height:24px;position:relative;top:6px;
}
.ofb_bible_verses_completed_holder {
    height:300px;
    overflow: auto;
}
.ofb_bible_verses_completed_header {
    margin-right:15px;
}
.ofb_bible_verses_completed_header div {
    display:flex;
    flex-direction:row;
    justify-content: space-evenly;
}
.ofb_bible_verses_completed_header div div {
}
.ofb_bible_verses_completed_holder div div {
}
.ofb_bible_verses_completed_holder div {
    display:flex;
    flex-direction:row;
    justify-content: space-evenly;
}


.ofb_bible_verses_completed_gametype {
    width:75px;
}
.ofb_bible_verses_completed_verse {
    width:200px;
}
.ofb_bible_verses_completed_lastdate {
    width:125px;
}
.ofb_bible_verses_completed_version {
    width:50px;
}
.ofb_bible_verses_completed_lastthree {
    width:70px;
}
.ofb_bible_verses_completed_lastscore {
    width:50px;
}
.ofb_bible_verses_completed_best {
    width:50px;
}
.ofb_bible_verses_completed_attempts {
    width:50px;
}
.ofb_bible_verses_completed_errors {
    width:50px;
}











/* TODO */
.ofb_highlight_yellow {
    background:yellow;
}
.ofb_highlight_red {
    background:red;
}


/* PROJECTS */
.ofb_projects_holder {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.ofb_projects_urgent {
    background:orange;
}
.ofb_projects_row {
    display:flex;
    flex-direction:column;
    max-width:300px;
    border:1px solid gray;
    margin:5px;
    box-shadow: 2px 2px black;
    padding:5px;
}
.ofb_projects_title {
    text-align:right;
    width:30%;
}
.ofb_projects_date {
    text-align:left;
    padding-left:10px;
    width:30%;
}
.ofb_projects_duration {
    text-align:left;
    padding-left:10px;
    width:30%;
}



/* TODOManageOverdue */
.ofb_todo_overdue_holder {
    text-align:left;
    display:flex;
    flex-direction:column;
}
.ofb_todo_overdue_item {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    margin: 5px;
}
.ofb_overdue_item_title {
    margin: 0 10px;
    padding-left:22px;
    text-indent: -22px;
    font-size:14pt;
}
.ofb_overdue_item_controls {
    display:flex;
    flex-direction:row;
}

/* DAILY */
.ofb_daily_actions_holder {
    display:flex;
    flex-direction:column;
}
.ofb_daily_actions_button {
    margin: 1px 0;
    width:100%;
    cursor:pointer;
    font-size:18pt;
    font-weight:bold;
}
.ofb_daily_actions_completed {
    color:white;
    background:green;
}
.ofb_daily_actions_waived {
    background:yellow;
    color:black;
}
.ofb_daily_actions_failed {
    color:white;
    background:red;
}
.ofb_strike {
    text-decoration:line-through;
}
.ofb_bible_verse_selector_holder {
    display:flex;
    flex-direction:row;
    justify-content: space-evenly;
}
.ofb_bible_verse_select {
    width:30%;
    border:none;
}
.ofb_bible_sort_key_button {
    border:none;
    cursor:pointer;
}
.ofb_bible_verse_completed_row {
    cursor:pointer;
}
.ofb_bible_verse_completed_row:hover {
    background: #9f9f9f;
}
.ofb_bible_verses_completed_modal {
    display:block;
}

/* LEARNING */
.ofb_learning_main_div {
    border: 3px solid rgb(194, 194, 194);
    padding:0;
    height:400px;
    display:flex;
    flex-direction:column;
}
.ofb_learning_top {
    background:black;
    height:50px;
    display:flex;
    flex-direction:row;
    padding:0 0 0 5px;
    justify-content: space-between;
    color:white;
    align-items: center;
}
.ofb_learning_middle {
    height:270px;
}
.ofb_learning_quiz_report {
    height:270px;
    font-size:18pt;
}
.ofb_learning_stats_row {
    height:30px;
    background: #ececec;
    padding:2px;
    border:4px solid white;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.ofb_learning_bottom {
    height:50px;
    background:red;
    padding:0 5px 0 5px;
    display:flex;
    color:white;
    font-size:12pt;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
}
.ofb_learning_q_num {
    display:flex;
    color:white;
    font-size:18pt;
    align-items:center;
    width:60px;
}
.ofb_learning_edit_button {
    color:white;
    font-size:18pt;
    display:flex;
    align-items:center;
    padding:0 5px 0 5px;
    width:60px;
    justify-content: center;
    display:none;
}
.ofb_learning_cancel_x {
    color:white;
    font-size:18pt;
    display:flex;
    align-items:center;
    justify-content: center;
    width:60px;
}
.ofb_learning_q_category {
    color:white;
    display:flex;
    padding:0 5px 0 5px;
    overflow:hidden;
    width:100%;
    align-items: center;
}
.ofb_learning_flip_card {
    cursor:pointer;
}
.ofb_learning_next_card {
    cursor:pointer;
}
.ofb_learning_font_size {
    width:50px;
}
.ofb_learning_bottom_button_group {
    display:flex;
    flex-direction: row;
}
.ofb_learning_card_word {
    display:none;
}
.ofb_learning_question_textbox {
    overflow-y: scroll;
    font-size:36pt;
    max-height:300px;
    display:flex;

    justify-content: center;
    border:1px solid black;
    height:100%;
    font-family: Serif;
}
.ofb_learning_main_view {
    display:flex;
    flex-direction:column;
}
.ofb_learning_folder_heading {
    color:white;
    font-size:18pt;
    display:flex;
    align-items: center;
    width:100%;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space: nowrap;
    padding-left:10px;
}
.ofb_learning_folder_selector {
    overflow-y: scroll;
    height:330px;
    border:1px solid gray;
    padding-bottom:20px;
}
@media only screen and (max-height: 500px) {
    .ofb_learning_folder_selector {
        overflow-y: scroll;
        height:200px;
        border:1px solid gray;
        padding-bottom:20px;
    }
    .ofb_learning_main_div {
        border: 3px solid rgb(194, 194, 194);
        padding:0;
        height:200px;
        display:flex;
        flex-direction:column;
    }
}
.ofb_learning_folder_name {
    font-size:16pt;
    padding: 0px 5px 0px 5px;
}
.ofb_learning_folder_button {
    width:100%;
    background:white;
    border:0px;
    cursor:pointer;
    height:100%;
    padding:10px 0px 10px 0px;
}
.ofb_learning_folder_button:hover {
    background: #dedede;
}
.ofb_learning_folder_button:active {
    background: #c5c5c5;
}
.ofb_learning_folder_stats {
    font-size:10pt;
}
.ofb_learning_question_preview {
    overflow: clip;
    border-top: 1px solid gray;
    display:flex;
    flex-direction: row;
    justify-content: start;
    font-size:18pt;
}
.ofb_learning_status_div {
    width:75px;
}
.ofb_learning_question_text_preview {
    width:100%;
}
.ofb_learning_question_stats_preview {
    width:75px;
    font-size:10.5pt;
    display:flex;
    justify-content: center;
    align-items: center;
    padding-right:10px;
}
.ofb_learning_thumbs {

}
.ofb_learning_thumbs_pressed {
    background:gray;
}
.ofb_learning_edit_folder_button {
    color:white;
    border:0;
    font-size:18pt;
    width:50px;
    height:100%;
    cursor:pointer;
}
.ofb_learning_question_edit_button {
    display:flex;
}
.ofb_learning_edit_folder_button_not_editing {
    background:black;
}
.ofb_learning_edit_folder_button_editing {
    background: #ff0000;
}
.ofb_learning_add_question_button {
    background:blue;
    color:white;
    font-size:18pt;
    width:50px;
    height:100%;
    border:0;
    cursor:pointer;
}
.ofb_learning_new_question_textarea {
    width:100%;
    min-height:200px;
}
.ofb_learning_edit_question_button {
    border:0px;
    background:none;
    cursor:pointer;
}
.ofb_learning_edit_question_button:hover {
    background: #c5c5c5;
}
.ofb_learning_new_folder_button {
    background: none;
    height:100%;
    width:50px;
    border:0;
    cursor:pointer;
}
.ofb_learning_new_subfolder_input {
    width:100%;
}
.ofb_learning_thing_to_delete {
    border:1px solid black;
    padding:20px;
}
.ofb_learning_quiz_selector {
    border:1px solid black;
    padding:20px;
    margin:20px;
}
.ofb_learning_folder_stats_row {
    width:100%;
    border:1px solid red;
    height:30px;
    background:red;
    color:white;
    display:flex;
    align-items:center;
    justify-content: center;
}
.ofb_learning_practice_button_div {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content: center;
    background:black;
}
.ofb_learning_results_row {
    padding: 10px 5px 10px 5px;
    font-size:14pt;
}
.ofb_learning_close_results_button {
    background:red;
    color:white;
    width:100%;
    border:0px;
    cursor:pointer;
}
.ofb_todo_simple {
    display:flex;
    flex-direction: row;
    width:345px;
    flex-wrap:wrap;
}
.ofb_todo_simple_uncategorized {
    display:flex;
    flex-direction: column;
    width:345px;
    flex-wrap:wrap;
}
.ofb_todo_simple_box {
    border:1px solid black;
    padding:5px;
    width:171px;
    display:none;
    flex-direction:column;
}
.ofb_cleaning_item_box {
    border:1px solid black;
    padding:5px;
    width:171px;
    display:flex;
    flex-direction:column;
}
.ofb_todo_simple_box_wide {
    border:1px solid black;
    padding:5px;
    width:740px;
    display:none;
    flex-direction:column;
}
.ofb_todo_simple_item {
    text-align:left;
    padding-left: 15px ;
    text-indent: -15px ;
    cursor:pointer;
}
.ofb_todo_simple_item:hover {
    background: #d0d0d0;
}
.ofb_strike {
    text-decoration: line-through;
}
.ofb_light_yellow {
    background: #ffff98;
}
.ofb_modal_header {
    background:red;
    width:100%;
    height:50px;
    color:white;
    display:flex;
    align-items:center;
    justify-content: space-between;
    font-size:16pt;
}
.ofb_modal_header div {
    margin: 0 15px 0 15px;
}
.ofb_modal_footer {
    background:black;
    width:100%;
    height:50px;
    color:white;
    display:flex;
    align-items:center;
    justify-content: center;
}
.ofb_modal_footer_button {
    /*background:none;*/
    border:none;
    cursor:pointer;
    padding:10px;
}
.ofb_modal_footer_button:hover {
    background: rgb(190, 190, 190);
}
.ofb_modal_footer_button:focus {
    border:1px solid red;
}
.ofb_new_todo_form {
    display:flex;
    flex-direction:column;
    font-size:12pt;
    padding:10px;
}
.ofb_new_todo_form_row {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    margin-right:20px;
}
.ofb_new_todo_form_text_input {
    margin: 0 0 3px 5px;
    width:320px;
}
.ofb_new_todo_form_checkbox_input {
    margin: 0 0 3px 10px;
    width:30px;
    height:30px;
}
.ofb_new_todo_form_checkbox_row {
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    padding-right:40px;
}
.ofb_new_todo_form_checkbox_row div {
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-left:10px;
}
.ofb_dashed_line_bottom {
    border-bottom:3px dashed black;
    margin-bottom:5px;
}
.ofb_checkbox_large {
    width:30px;
    height:30px;
    margin:3px;
}
.ofb_todo_simple_checkbox_row {
    display:flex;
    flex-direction:row;
    justify-content: center;
}
.ofb_cursor_pointer {
    cursor:pointer;
}
.ofb_todo_edit_title_input {
    width:225px;
}
.ofb_font_size_12 {
    font-size:12pt;
}
.ofb_todo_mark_completed_button_div {
    margin:15px 0 15px 0;
}
.ofb_todo_completed_row {
    display:flex;
    flex-direction:row;
    justify-content: space-evenly;
}
.ofb_todo_ays {
    color:red;
    border: 3px dashed #9d9d9d;
    padding:10px;
    margin:10px 30px 10px 30px;
    font-size:12pt;
}
.ofb_new_cleaning_form_row {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    margin-right:20px;
}
.ofb_cleaning_category_select {
    padding:4px;
    background:white;
}
.ofb_cleaning_text_input {
    display:inline;
    margin: 0 0 3px 5px;
    width:38%;
}
.ofb_deephasized_text {
    color: #d2d2d2;
}
@media only screen and (min-width: 750px) {
    .ofb_modal_content{margin:auto;background-color:#fff;position:relative;padding:0;outline:0;max-width:600px;}

    .ofb_learning_main_div {
        border: 3px solid rgb(194, 194, 194);
        padding:0;
        height:400px;
        display:flex;
        flex-direction:column;
    }
    .ofb_learning_edit_button {
        display:flex;
    }
    .ofb_learning_bottom {
        font-size:18pt;
    }
    .ofb_learning_card_word {
        display:inline;
    }
    .ofb_todo_simple {
        width:726px;
    }
    .ofb_todo_simple_box {
        width:358px;
    }
    .ofb_cleaning_item_box {
        width:358px;
    }
    .ofb_new_todo_form_row {
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        margin-right:20px;
    }
    .ofb_new_todo_form_checkbox_row {
        display:flex;
        flex-direction:row;
        justify-content:end;
        padding-right:40px;
    }
    .ofb_new_todo_form_checkbox_row div {
        display:flex;
        flex-direction:row;
        align-items:center;
        margin-left:10px;
    }

}
