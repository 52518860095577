.import_transactions_textbox {
    width:100%;
    height:300px;
    border: 1px solid black;
    padding:5px;
}
.import_button {
    border:0;
    padding:5px;
    background:red;
    color:white;
}


/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 18px;
    margin:0 5px 0 5px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ff0000;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #000000;
}

input:focus + .slider {
    box-shadow: 0 0 5px 5px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(72px);
    -ms-transform: translateX(72px);
    transform: translateX(72px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 16px;
}

.slider.round:before {
    border-radius: 50%;
}
.income_expense_slider_row {
    display:flex;
    flex-direction:row;
    align-items:center;
    margin-left:5px;
}
.simple_entry_row {
    display:flex;
    flex-direction:row;
    align-items:center;
    margin:5px;
    width:100%;
}
.simple_entry_row_general_input {
    margin-left:5px;
    width:300px;
}
.simple_entry_row div select {
    margin-left: 5px;
    width:300px;
    padding:5px;
}
.simple_entry_row div select:focus {
    box-shadow: 0 0 5px 5px #2196F3;
    border: 3px solid black;
}.simple_entry_row div input:focus {
    box-shadow: 0 0 5px 5px #2196F3;
    border: 3px solid black;
}
.simple_entry_label_div {
    width:100px;
    display:flex;
    flex-direction:row;
    justify-content: end;
}
.simple_entry_mod_button {
    padding:5px;
    cursor:pointer;
    border:0;
    background:red;
    color:white;
    margin-left:3px;
}
.simple_entry_button {
    background:red;
    color:white;
    padding:5px;
    cursor:pointer;
    border:0;
    margin-left:3px;
}
.simple_entry_button:focus {
    box-shadow: 0 0 5px 5px #2196F3;
    border: 3px solid black;
}
.simple_entry_centered_buttons_cell {
    display:flex;
    flex-direction:row;
    justify-content: center;
    width:300px;
    margin-left:5px;
}
.simple_entry_amount_input {
    width:124px;
    margin-left:5px;
    padding:3px;
}
.simple_entry_date_holder_div {
    padding-left:5px;
}
.simple_entry_memo_input {
    width:171px;
    margin-left:5px;
    padding:3px;
}
.simple_entry_transaction_total {
    font-weight:bold;
}
.simple_entry_split {
    background: #dadada;
    max-width:500px;
}
.delete_split_button {
    cursor:pointer;
    border:0;
    background:red;
    color:black;
    margin-left:3px;
    width:30px;
    font-weight:bold;
    height:30px;
    display:flex;
    justify-content: center;
    align-items:center;
    font-size:12pt;
    flex-direction:row;
    padding:0 1px 0 0;
}
.delete_split_button_image {
    height:12px;
}
.new_category_button {
    cursor:pointer;
    border:0;
    background:red;
    color:black;
    margin-left:3px;
    width:30px;
    font-weight:bold;
    height:30px;
    display:flex;
    justify-content: center;
    align-items:center;
    font-size:12pt;
    flex-direction:row;
    padding:0 1px 0 0;
}
.simple_entry_preview_div {
    text-align: left;
    white-space: pre;
}
.reconciliation_row {
    text-align:left;
    cursor:pointer;
}
.reconciliation_row:hover {
    background: #c2c2c2;
}
.red_cell {
    color:red;
}
.reconciliation_balances_div {
    display:flex;
    flex-direction:row;
    justify-content: center;
}
.reconciliation_table_div {
    display:flex;
    flex-direction: column;
    height:300px;
    overflow:hidden;
    overflow-y:scroll;
}
.reconciliation_table_div table {
}
.sub_total_div {
    padding:15px;
}
.reconciliation_main_div {

}
.delete_reconciliation_button {
    background:red;
    color:white;
}
.closing_balance_rec_ledger {
    margin-left:30px;
}
.ofb_finance_transaction_disabled {
    background: #b9b9b9;
    width:100%;
    border:1px solid #ccc;
    margin-bottom:15px;
    box-shadow:3px 3px #ababab;
    cursor:pointer;
}
.graph_canvas {
    border:1px solid black;
}
.graph_canvas_div {
    padding:1px;
    overflow: auto;
}



/* Budget Month Graph */
.month_budget_graph_container {
    border:1px solid black;
    padding:20px;
}
.month_year_selector_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}

.selector_label {
    font-size: 14px;
    font-weight: bold;
}

.selector {
    padding: 5px;
    font-size: 14px;
}

.selector_label select {
    width: 150px; /* Adjust width for the select dropdown */
}

.month_year_selector_container label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.selector_label select:focus {
    border-color: #9a730d; /* Highlight border color */
    outline: none;
}
