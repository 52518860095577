.last_item {
    border-radius: 0px 0px 5px 5px;
    background:black;
}
.nav_image {
    border-radius: 5px 5px 0px 0px;
    width:100%;
}
.nav_block {
    margin: 0 auto;
    width: 60%;
    border-radius: 5px;
}
.nav_row {
    margin-top: 50px;
}
.nav_section {
    max-width: 800px;
}
.nav_block_items_holder {
    border-radius: 0px 0px 15px 15px;
}