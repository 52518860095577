.container {
    display:flex;
    flex-direction:row;
    width:100%;
    padding:0 30px 0 30px;
    justify-content:center;
}
.main {
    border: 1px solid #e4e4e4;
    padding:20px;
    display:flex;
    flex-direction:row;
    width:90%;
}
.title_entry {
    width:100%;
    border: 1px solid #b6b6b6;
}
.submit_button {
    border:0;
    background:red;
    color:white;
    width:100px;
    height: 40px;
}