.prayer_header {
    padding:5px;
    font-size:18pt;
    background: black;
    color:white;
    cursor:pointer;
}
.prayer_category {
}
.prayer_item {
    padding:3px;
    font-size:14pt;
    cursor:pointer;
}
.prayer_item:hover {
    background: #d5d5d5;
}
.last_date_span {
    color:red;
    font-size:9pt;
    padding-left:5px;
}
.completed_row {
    display:flex;
    flex-direction:row;
    justify-content: space-evenly;
}
.mark_completed_button_div {
    margin:15px 0 15px 0;
}
.edit_title_input {
    width:225px;
}
.widget_prayer_item_text {
    font-size:3vh;
}