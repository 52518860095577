/* Adapted from the tutorial https://www.startutorial.com/articles/view/how-to-build-a-web-calendar-in-php
  by Xu Ding, thedilab@gmail.com */

/*******************************Calendar Top Navigation*********************************/
div#calendar{
  margin:0 auto;
  padding:0;
  /*width: 602px; */
  width: 322px;
  font-family:Helvetica, "Times New Roman", Times, serif;
}
 
div#calendar div.box{
    position:relative;
    top:0;
    left:0;
    width:100%;
    height:40px;
    background-color: #000000;
}
 
div#calendar div.header{
    line-height:40px;  
    vertical-align:middle;
    position:absolute;
    left:11px;
    top:0;
    /*width:582px;*/
    width:302px;
    height:40px;   
    text-align:center;
    color:white;
}
/*
div#calendar div.header button.prev,div#calendar div.header button.next{
    position:absolute;
    top:0;
    height: 17px;
    display:block;
    cursor:pointer;
    text-decoration:none;
    color:#FFF;
} */
.prev {
    border:none;
    background:black;
    color:white;
    cursor:pointer;
}
.next {
    border:none;
    background:black;
    color:white;
    cursor:pointer;
}
#calendar_navigation_header {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    color:white;
}
 
div#calendar div.header span.title{
    color:#FFF;
    font-size:18px;
}
 
 
div#calendar div.header a.prev{
    left:0;
}
 
div#calendar div.header a.next{
    right:0;
}
 
 
 
 
/*******************************Calendar Content Cells*********************************/
div#calendar div.box-content{
    border:1px solid #787878 ;
    border-top:none;
}
 
 
 
div#calendar ul.label{
    float:left;
    margin: 5px 0 0 5px;
    padding: 0;
}
 
div#calendar ul.label li{
    margin:0 5px 0 0;
    padding:0;
    float:left;
    list-style-type:none;
    /*width:80px;*/
    width:40px;
    height:40px;
    line-height:40px;
    vertical-align:middle;
    text-align:center;
    color:#000;
    font-size: 15px;
    background-color: transparent;
}
 
 
.dates_holder {
    display:flex;
    margin:0 0 0 0;
    padding:0 0 5px 5px;
    flex-wrap: wrap;
    flex-direction:row;
}
.date_cell {
    margin:5px 5px 0 0;
    padding:0;
    line-height:40px;
    vertical-align:middle;
    float:left;
    list-style-type:none;
    width:40px;
    height:40px;
    font-size:18px;
    background-color: #DDD;
    color:#000;
    text-align:center;
    cursor:pointer;
}
.date_cell_events {
    color:red;
    font-weight:bold;
}

 
:focus{
    outline:none;
}
 
div.clear{
    clear:both;
}
.eventClass {
    color:red;
    cursor:pointer;
}
.nonEventClass {
    cursor:pointer;
}
#calendar_and_events_holder {
    width:100%;
    justify-content:center;
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
}
#events_holder {
    margin:20px;
    min-width:300px;
}
#events_holder .header {
    background:black;
    color:white;
}
#calendar_events {
    padding:0 10px 10px 0;
    text-align:left;
    border:1px solid gray;
}
#calendar_events ul {
    list-style: outside none none;
    padding-left:20px;
}
.calendar_events_list_date {
    font-size: 9pt;
}
.calendar_modal_event_item {
    padding: 10px 0 10px 0;
    cursor: pointer;
}
.calendar_modal_event_details {
    background: #f3f3f3;
    padding:10px;
    display:flex;
    flex-direction:row;
    align-items: center;
}
.calendar_click_for_details {
    font-size:7pt;
    font-style: italic;
}
#new_event_details_input {
    height:80px;
}
#add_new_event_holder {
    border-top: 5px dashed #d0d0d0;
}
#new_event_date_time_holder {
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content:center;
}
.calendar_time_span {
    font-size:8pt;
}
.calendar_time_span span {
    color:red;
}