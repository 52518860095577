.toggleButton, .toggleButtonUnchecked, .toggleButtonChecked {
    padding: 0px 2px 0px 2px;
    font-size:9pt;
}
.toggleButtonUnchecked {
    /* background: #cccccc; */
}
.toggleButtonChecked {
    background: #32a63c;
}
.toggleButtonInput {
    display:none;
}
.toggleButtonLabel {
    cursor:pointer;

}