.todo_simple {
    display:flex;
    flex-direction: row;
    max-width:600px;
    flex-wrap:wrap;
}
.todo_simple_box {
    border:1px solid black;
    padding:5px;
    width:50%;
    display:flex;
    flex-direction:column;
}
.todo_simple_item {
    text-align:left;
    padding-left: 15px ;
    text-indent: -15px ;
    cursor:pointer;
}
.close_button {
    background:red;
    color:white;
    width:100%;
    border:0px;
    cursor:pointer;
}
.edit_title_input {
    width:225px;
}
.completed_row {
    display:flex;
    flex-direction:row;
    justify-content: space-evenly;
}
.mark_completed_button_div {
    margin:15px 0 15px 0;
}
.new_form {
    display:flex;
    flex-direction:column;
    font-size:12pt;
    padding:10px;
}
.new_form_row {
    display:flex;
    flex-direction: column;
    width:100%;
    margin-top:10px;
}
.new_form_text_input {
    width:100%;
}
.page_select {
    height:45px;
    font-size:14pt;
    width:100%;
}
.selector_row {
    width:100%;
    display:flex;
    flex-direction:row;
}
.add_new_page_button {
    background:red;
    color:white;
    border:0;
    width:50px;
    font-size:18pt;
}