.quizContainer {
    border: 2px solid black;
    padding: 20px;
    margin: 20px auto;
    max-width: 600px;
    background-color: #fff;
    border-radius: 10px;
}
.flash_green {
    background:green;
}
.flash_red {
    background:red;
}

.stats {
    text-align: center;
    margin-bottom: 20px;
}

.quizBox {
    text-align: center;
    margin-bottom: 20px;
}

.quizSentence {
    font-size: 1.5em;
    color: #000;
}

.quiz_input {
    border: 1px solid #5d4508;
    padding: 5px;
    font-size: 1em;
    width:150px;
}

.submitButton, .printButton {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
}

.submitButton:hover, .printButton:hover {
    background-color: #5d4508; /* Slightly darker gold */
}

.nameBox {
    margin-top: 20px;
    text-align: center;
}

.printView {
    padding: 20px;
    text-align: center;
    background-color: #fff;
    color: #000;
}
.correct {
    color:white;
}
.incorrect {
    color:white;
}

.statsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.stats {
    flex: 1;
    text-align: center;
}

.randomizeContainer {
    flex:1;
    font-size: 0.9em;
    color: #9a730d; /* Dark gold */
    display:flex;
    justify-content: end;
}
.top_row_left {
    flex:1;
}

.randomizeContainer input {
    margin-right: 5px;
}