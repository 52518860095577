.widget_memory_item_text {
    font-size:3vh;
}
.widget_ref {
    font-size:3vh;
}
.button_row {
    display:flex;
    flex-direction:row;
    width:100%;
}
.nav_button {
    width:10%;
    font-size:18pt;
}
.widget_word {
    max-width:80%;
    width:80%;
    overflow-x: auto;
    font-size:5vh;
}
.greek_reading_pane {
    border:1px solid #afafaf;
    padding:30px;
}
.greek_verse {
    margin-bottom:5px;
    display:flex;
    flex-direction:row;
    align-items: start;
}
.greek_verse_number {
    width:50px;
    text-align:right;
    margin-right:10px;
}
.greek_verse_text {
    text-align:left;
    width:600px;
}