/* TODAY */
.actions_holder {
    display:flex;
    flex-direction:column;
}
.actions_button {
    margin: 1px 0;
    width:100%;
    cursor:pointer;
    font-size:18pt;
    font-weight:bold;
}
.actions_completed {
    color:white;
    background:green;
}
.actions_waived {
    background:yellow;
    color:black;
}
.actions_failed {
    color:white;
    background:red;
}
.actions_title {
    font-size:24pt;
}
.details_editing_visible {
    display:block;
    text-align:left;
    border: 2px solid #b6b6b6;
    padding: 5px;
}
.edit_form_element {
    padding-bottom:10px;
}
.edit_save_button {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.day_of_the_week_selector {
    display:flex;
    flex-direction:row;
    padding: 0px 10px 0px 10px;
}
.daily_item {
    display:flex;
    flex-direction:row;
    align-items: center;
}
.daily_item_title {

}
.daily_item_title_inactive {
    background: #606060;
    color:white;
}
.daily_item_title_editing {
    cursor:pointer;
}