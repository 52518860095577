.meal_list_item {
    cursor:pointer;
    margin-bottom:10px;
}
.meal_date {
    font-weight:bold;
}
.bold {
    font-weight:bold;
}
.food_row {
    border:1px solid black;
    width:100%;
    display:flex;
    flex-direction:column;
}
.food_row_name_div {
    width:100%;
}
.food_row_units_div {
    display:flex;
    flex-direction:row;
    width:100%;
}
.food_row_name {
    width:100%;
    height:100%;
    border:1px solid #b6b6b6;
    margin:0px;
}
.cal_unit_input {
    width:20%;
    border:0px;
}
.units_input {
    width:15%;
    border-width:0 0 0 1px;
    border-color: #b6b6b6;
}
.unit_type_input {
    width:12%;
    border-width:0 1px 0 1px;
    border-color: #b6b6b6;
}

@media only screen and (min-width: 750px) {
    .food_row {
        border:1px solid black;
        width:100%;
        display:flex;
        flex-direction:row;
    }
    .food_row_name_div {
        width:65%
    }
    .food_row_units_div {
        display:flex;
        flex-direction:row;
        width:35%;
    }
}