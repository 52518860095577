.bulk_import_textarea {
    padding:5px;
    width:100%;
    border:none;
    height:250px;
}
.bulk_import_form_row {
    display:flex;
    flex-direction:column;
    border:1px solid black;
    justify-content: center;
    padding:5px;
}
.test_data_div {
    padding:10px;
}
.test_results_div {
    padding:5px;
    width:100%;
    border:none;
    overflow-x: scroll;
    text-align:left;
}
.label_row {
    overflow-x: scroll;
}
.label_data_row {
    text-align:left;
    white-space: nowrap;
}
.label_printed_row {
    text-align:left;
    white-space: nowrap;
    background:black;
    color:white;
}
.label_table {
    border:1px solid black;
    padding:3px;
}
.data_cell {
    overflow: visible;
}
.label_checkbox {
    width:25px;
    height:25px;
    margin:5px;
}
.gray_row {
    background: #cecece;
}
.label_sheet {
    width:4in;
    height:6in;
    border:1px solid gray;
    padding:45px 12px 54px 33px;
    /* background:blue; */
    display:flex;
    flex-direction:column;
}
.label_row {
    display:flex;
    flex-direction:row;
    overflow-x:unset;
    justify-content: space-between;
    width:3.5in;
}
.label {
    width:1.6875in;
    height:47px;
    font-size:10pt;
    line-height: .97em;
    margin-top:1px;
    padding-top:4px;
    text-align:left;
    padding-left:60px;
}
.label_background_lightgray {
    background: #eaeaea;
}
.banned_label {
    color: white;
    background: black;
}
.banned_label_no_background {
    color:white;
    background:none;
}
.label_row_1 {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.author_mini_label {
    font-size: 7pt;
    padding-right:3px;
}
.master_category {
    font-weight:bold;
    color: #8d0000;
    background: #ffff7b;
}
.sub_label {
    color:black;
}