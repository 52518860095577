.main_content {
    text-align: left;
}
.section_heading {
    font-weight:bold;
    text-transform: uppercase;
    font-size:14pt
}
.section {
    margin-bottom:20px;
}
.subsection_heading {
    font-style: italic;
    text-transform:uppercase;
}
.calendar_events_div {
    margin-bottom:5px;
}
.calendar_events_div ul {
    margin-top:0;
    margin-bottom:0;
}
.daily_events_div {
    margin-bottom:5px;
}
.daily_events_div ul {
    margin-top:0;
    margin-bottom:0;
}
.todo_items_div {
    margin-bottom:5px;
}
.todo_items_div ul {
    margin-top:0;
    margin-bottom:0;
}
.cycle_items_div {
    padding:0 5px 2px 20px;
    cursor:pointer;
}
.never_done {
    background: #cbcbcb;
}
.overdue {
    background: #ff8d8d;
}
.due_now {
    background:yellow;
}
.completed {
    text-decoration: line-through;
}
.cycle_category_holder {
    border:1px solid gray;
    padding:5px;
    background: red;
    color:white;
    cursor:pointer;
}
.cycle_items_holder {
    color:black;
    background:white;
}
.b {
    border:1px solid black;
}