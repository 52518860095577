.widget_memory_item_text {
    font-size:3vh;
}
.widget_category {
    font-size:2vh;
}
.button_row {
    display:flex;
    flex-direction:row;
    width:100%;
}
.thumbs_button {
    width:15%;
    padding:0px;
    font-size:18pt;
    background:none;
    border:none;
}
.question_answer_text_box {
    max-width:80%;
    width:80%;
    overflow-x: auto;
    font-size:3vh;
}
.folder_name {
    font-size:16pt;
    padding: 0px 5px 0px 5px;
}
.folder_button {
    width:100%;
    background:white;
    border:0px;
    cursor:pointer;
    height:100%;
    padding:10px 0px 10px 0px;
}
.folder_button_highlighted {
    background:#dedede;
}
.folder_button:hover {
    background: #dedede;
}
.folder_button:active {
    background: #c5c5c5;
}