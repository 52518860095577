.church_link {
    color: #64aace;
    text-decoration: none;
}
.church_sub_item {
    margin-left:20px;
}
.numbered_list_item {
    margin-bottom: 10px;
}
.bullet_list {
    margin-bottom: 10px;
}
strong {
    color: #606060;
}