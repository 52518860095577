

h4{
    font-family: 'Segoe UI', bold;
    font-size: 35px;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

form{
    display: inline-block;
    align-items: center;
    width: 80%;
    margin-bottom: 40px;
}
.text_area{
    align-content: center;
    border-radius: 22px;
    margin-bottom: 35px;
    width: 100%;
    height: 64px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2), 0 0 20px 0 rgba(0, 0, 0, 0.19);
}

.text_input{
    margin-top: 1.2rem;
    font-family: 'Montserrat regular';
    font-size: 20px;
    border: none;
    width: 80%;
}

.btn{
    margin-top: 30px;
    width: 165px;
    height: 60px;
    border-radius: 30px;
    background: #000000;
    border: none;
    font-family: 'Montserrat';
    font-size: 20px;
    color: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(215, 215, 215, 0.7), 0 6px 20px 0 rgba(87, 87, 87, 0.9);
    cursor: pointer;
}

.login{
    display: inline-block;
    background: #FFFFFF;
    width: 90%;
    max-width:435px;
    height: 450px;
    margin-top: 0%;
    margin-bottom:5%;
    border-radius: 22px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}