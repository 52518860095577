.item_holder {

}
.item_button {
    text-align:left;
    padding-left:22px;
    text-indent:-22px;
    border:0px;
    background:none;
    cursor:pointer;
}
.item_button:hover {
    background: #d9d9d9;
}
.top_level_item {
    font-weight:bold;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
}
.second_level_holder {
}
.top_level_holder {
    display:none;
}
.modal_field_label {
    font-weight:bold;
}
.modal_item_title {
    font-size:14pt;
    padding:5px;
    text-align:left;
}
#modal_events_holder {
    display:flex;
    flex-direction:column;
}
.modal_item {
    text-align: left;
    padding-left:22px;
    text-indent:-22px;
    margin-left:30px;
}
#modal_footer {
    display:flex;
    flex-direction:row;
    vertical-align: middle;
}
.modal_button {
    background:none;
    border:none;
    cursor:pointer;
    padding:15px;
}
.modal_button:hover {
    background:rgba(0,0,0,0.3);
}
.modal_message_holder {
    padding:5px 30px 5px 30px;
    font-size:14pt;
    font-style:italic;
    color:red;
}
.modal_add_new_header {
    font-weight:bold;
}
.insert_item_below_holder {
    margin-top:30px;
}
.insert_item_below_form_cell {
    display:flex;
    flex-direction:row;
    align-items: center;
    padding:3px 10px 3px 10px;
}
.ofb_check {
    padding:8px;
    display:inline-block;
    border:none;
    border-bottom:1px solid #ccc;
    width:24px;
    height:24px;
    position:relative;
    margin: 0px 5px 0px 5px;
}
.new_item_submit_button {
    margin-top:30px;
}
.ofb_label {
    font-weight:bold;
    flex:0 0 auto;
}
.ofb_text_input {
    padding:8px;
    display:block;
    border:none;
    border-bottom:1px solid #ccc;
    width:100%;
}
.ofb_input_holder {
    flex: 1 0 auto;
}
.urgent_item {
    background: #ffb94e;
}
.active_item {
    background: #679de1;
}
.ofb_todo_project {
    border:2px solid rgba(0, 0, 0, 0.29);
    background: rgba(227, 227, 227, 0.68);
}
.record_work_progress_holder {

}
.ofb_error_message {
    color:red;
    font-size:14pt;
}
.ofb_hide {
    display:none;
}
.ofb_progress_note {
    margin-left:15px;
}
.ofb_progress_note_duration {
    font-size:10pt;
    color:red;
}
.ofb_progress_note_datetime {
    font-size:12pt;
}
.ofb_progress_note_notes {
    font-style: italic;
}
.todo_simple_button {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.parent_category_selector {
    font-size:12pt;
    display:flex;
    flex-direction:row;
}
.parent_selector {
    width:100%;
}