.alphabet_game_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin:0 20px 0 20px;
    border: 1px solid black;
    border-radius: 5px;
}

.game_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.letters_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    max-width: 400px;
}

.letter_tile {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9a730d; /* Dark gold */
    color: white;
    font-weight: bold;
    font-size: 1.5em;
    border-radius: 5px;
    cursor: grab;
    user-select: none;
}

.letter_tile:active {
    cursor: grabbing;
}

.drop_zones {
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 20px;
    margin-top: 10px;
}

.drop_box {
    flex: 1;
    height: 150px;
    border: 2px dashed #624c0e; /* Dark gold shade */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    border-radius: 10px;
}

.drop_box h2 {
    font-size: 1.2em;
    color: #624c0e; /* Dark gold shade */
    margin: 0;
    text-align: center;
}

.score_display {
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    color: #624c0e; /* Dark gold */
}

.score_display h2 {
    margin-bottom: 10px;
}

.score_display p {
    color: #333; /* Dark gray */
}

.alphabet_game_container {
    transition: background-color 0.5s ease-in-out;
}

.play_again_button {
    background-color: #9a730d; /* Dark gold */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.play_again_button:hover {
    background-color: #815e0b; /* Slightly darker gold on hover */
}

