.pointer {
    cursor: pointer;
}
.day_of_the_week_selector {
    display:flex;
    flex-direction:row;
    padding: 0px 10px 0px 10px;
}
.dailyItem {
    display:flex;
    flex-direction:row;
    align-items: center;
}
.dailyItemTitleInactive {
    background: #606060;
    color:white;
}
.dailyItemTitle {

}
.dailyItemTitleEditing {
    cursor:pointer;
}
.dailyDetailsEditingVisible {
    display:block;
    text-align:left;
    border: 2px solid #b6b6b6;
    padding: 5px;
}
.dailyDetailsEditingHidden {
    display:none;
}
.dailyEditFormElement {
    padding-bottom:10px;
}
.dailyEditSaveButton {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.hidden {
    display:none;
}
.todoItem {
    padding: 5px;
    text-align:left;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.todoItemHeader {
    background:black;
    color:white;
    font-weight:bold;
    margin-top: 30px;
}
.dueToday {
    background: #ffffb3;
}
.overdue {
    background: #ff9393;
}

.optionsButton {
    font-weight: bold;
}