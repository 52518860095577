.wobble_grid {
    font-family: monospace;
    font-size:36pt;
    line-height:48px;
    font-weight:bold;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
}
.wobble_row div {
    margin-right:8px;
    border: 1px black solid;
    width:64px;
    height:64px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.wobble_row {
    display:flex;
    flex-direction:row;
}
.clock {
    font-size:36pt;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
}
.clock_stopped {
    color:red;
}
.wobble_game_main_row {
    display:flex;
    flex-direction:row;
}
.wobble_answer_column {
}
.wobble_answer_column textarea {
    height:100%;
}
.score_span {
    font-size:24pt;
    margin-left:50px;
}