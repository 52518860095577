.main_content {
    text-align: left;
}
.header_row {
    display:flex;
    flex-direction: row;
}
.category {
    font-weight:bold;
    cursor:pointer;
    padding:3px;
    display:flex;
    flex-direction: row;
}
.category:hover {
    background: #9f9f9f;
}
.item {
    margin-left:10px;
    display:flex;
    flex-direction:row;
    align-items:center;
    cursor:pointer;
}
.item:hover {
    background: #9f9f9f;
}
.checkbox_holder {
    width:30px;
    display:flex;
    justify-content: center;
    font-size:15pt;
    height:30px;
}
.category_holder {
}
.items_holder {
    margin-bottom: 15px;
}
.add_new_item_button {
    border:0;
    margin: 0 0 0 10px;
    cursor:pointer;
}
.edit_category_button {
    border:0;
    margin-left:2px;
    cursor:pointer;
}
.close_button {
    border:none;
    background:none;
    font-weight:bold;
}
.itemModalRow {
    display:flex;
    flex-direction:row;
}
.checkbox_container {
    width:50px;
    display:flex;
    flex-direction:row;
    justify-content: end;
}
.item_title {

}
.item_checkbox {
    width:24px;
    margin-right:6px;
}
.ays {
    display: flex;
    flex-direction:row;
}
.ays_message {
    padding:5px;
    color:white;
}
.search_results {
    padding:10px;
    margin-left:10px;
}
.search_box_holder {
    display:flex;
    flex-direction:row;
}