/* Circle CSS Adapted from https://codepen.io/bbiskynis/pen/bGKoPbg */
.connection_info {
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items:center;
  margin-top:30px;
}
.connection_info_message {
}
.circles {
  margin-right:15px;
  width: 20px;
  height: 20px;
}
.mid_circle{
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50%;
  position: absolute;
}
.mid_circle_red {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
}

.red_circle{
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
}
.green_circle{
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50%;
  position: absolute;
}

.one{
  animation-name: expand1;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0s;
}

.two{
  animation-name: expand1;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 1s;
}

.three{
  animation-name: expand1;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 2s;
}
.hide {
  display:none;
}

@keyframes expand1{
  0%{
    transform: scale(1);
    opacity:1;
  }
  
  80%{
    transform: scale(1.8);
    opacity:.6;
  }
  
  100%{
    transform: scale(2);
    opacity:0;
  }
}
.tiny_button {
  font-size:6pt;
  height:15px;
}
.students_table_div {
  display:flex;
  flex-direction:column;
  align-items: center;
}
.students_table {
  margin:15px;
  box-shadow: 4px 4px #bdbdbd;
  border:1px solid #bdbdbd;
  padding:10px;
  min-width:60%;
}
.odd_row {
  background: #e3e3e3;
}
.first_name_column {
  text-align:right;
}
.student_header_row {
  background: #3d3d3d;
  font-weight:bold;
  color:white;
}

.green_status_circle {
  width: 14px;
  height: 14px;
  background-color: green;
  border-radius: 50%;
}
.red_status_circle {
  width: 14px;
  height: 14px;
  background-color: red;
  border-radius: 50%;
}
.black_status_circle {
  width: 14px;
  height: 14px;
  background-color: black;
  border-radius: 50%;
}
.students_table_cell {
  min-height:24px;
  justify-content: center;
  display:flex;
  flex-direction:row;
  align-items: center;
}
.question_builder {
  display:flex;
  flex-direction:column;
  border:3px solid black;
}
.choices_builder {
  display:flex;
  flex-direction:row;
  width:100%;
  justify-content: center;
  border:1px solid black;
}
.choices_builder div input {
  width:100%;
  border:0;
  text-align:center;
}
.possible_count_div {
  width:50%;
}
.correct_answer_div {
  width:50%;
}
.question_title {
  width:100%;
  text-align:center;
}
.question_type_select {
  padding:5px;
  text-align:center;
}
.student_login_div {
  border:1px solid black;
  padding: 5px;
  box-shadow: 4px 4px #d9d9d9;
}
.student_login_div div input {
  width: 98%;
  border:0;
  text-align:center;
  font-size:14pt;
  background: #dcdcdc;
  margin:5px;
}
.student_login_div div button {
  width:98%;
  font-size:14pt;
  margin:5px;
}
.simple_button {
  border: 1px solid black;
  background:white;
  cursor:pointer;
}
.simple_button:hover {
  background: #b6b6b6;
}
.student_interaction_div {
  padding:10px;
  border:1px solid black;
  min-height:300px;
  margin:5px 0 20px 0;
  box-shadow: 4px 4px #8a8a8a;
}
.deployed_question {
  border:3px solid black;
}
.deployed_question_header {
  background:#3d3d3d;
  color:white;
  font-weight:bold;
  padding:5px;
}
.deployed_question_body {
  margin:5px;
  padding:10px;
}
.student_question_text {
  font-size:24pt;
}
.answer_buttons {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
}
.answer_buttons button {
  min-width:200px;
  margin:3px;
  box-shadow: 2px 2px gray;
  padding:5px;
  min-height:50px;
  font-size:24pt;
}
.gray {
  background:gray;
}
.green {
  background:green;
}
.red {
  background:red;
}
.red_check {
  color:red;
  font-size:16pt;
}
.green_check {
  color:green;
  font-size:16pt;
}
.teacher_answer_cell {
  display:flex;
  flex-direction:row;
  justify-content: space-evenly;
  width:100%;
  align-items: center;
}
.loading_gif {
  width:100%;
  max-width:512px;
}
.passphrase {
  background:black;
  color:white;
  font-size:28pt;
  padding:10px;
  box-shadow: 4px 4px gray;
}
.passphrase_holder {
  margin:10px;
}
.class_selector div {
  margin: 15px;
}
.animate_opacity {
  animation:opac 0.8s
}@keyframes opac{from{opacity:0} to{opacity:1}}

.tab_button {
  border:1px solid black;
  height:40px;
  background:white;
  color:black;
  cursor:pointer;
}
.tab_button_selected {
  border:1px solid black;
  height:40px;
  background:black;
  color:white;
  font-weight:bold;
  cursor:pointer;
}
.tab_buttons button:hover {
  background:#b6b6b6;
}
.class_selector_input {
  padding:5px;
  font-size:20pt;
  background: #ff9c9c;
  margin:10px;
}
.tab_container {
  border:1px solid black;
  padding:20px;
  width:100%;
  margin-bottom:20px;
}
.question_builder_title {
  font-size:14pt;
  font-weight:bold;
  padding:10px;
  background: #e0e0e0;
}

.modal_hidden {z-index:3;display:none;padding-top:100px;position:fixed;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:rgb(0,0,0);background-color:rgba(0,0,0,0.4)}
.modal_shown {z-index:3;display:block;padding-top:100px;position:fixed;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:rgb(0,0,0);background-color:rgba(0,0,0,0.4)}
.modal_content{margin:auto;background-color:#fff;position:relative;padding:0;outline:0;max-width:350px;}
.modal_header {
  background:red;
  width:100%;
  height:50px;
  color:white;
  display:flex;
  align-items:center;
  justify-content: space-between;
  font-size:16pt;
}

.modal_header div {
  margin: 0 15px 0 15px;
}
.modal_footer {
  background:black;
  width:100%;
  height:50px;
  color:white;
  display:flex;
  align-items:center;
  justify-content: center;
}
.modal_footer_button {
  /*background:none;*/
  border:none;
  cursor:pointer;
  padding:10px;
  margin: 0 1px 0 1px;
}
.modal_footer_button:hover {
  background: rgb(190, 190, 190);
}
.modal_footer_button:focus {
  border:1px solid red;
}
.modal_body {
  padding:20px;
  font-size:18pt;
}
.qr_code {
  cursor:pointer;
}
.customize_answers_row {
  display:flex;
  flex-direction:row;
  justify-content: space-evenly;
  padding:5px 0 5px 0;
}
.customize_answers_row div {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.radio {
  height:30px;
  width:30px;
  margin-right:10px;
  color:black;
}
.number_input {
  font-size:16pt;
}
.custom_answers_holder {
  display:flex;
  flex-direction: column;
  padding:5px;
}
.custom_answers_holder input {
  border:0;
  background:lightgray;
  margin:1px;
  padding:5px;
  font-size:14pt;
  text-align:center;
}
.static_answer_div {
  border:1px solid black;
  padding:0 10px 0 10px;
}
.static_answers_holder {
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: center;
}
.outcomes_option {
  max-width: 100%;
  overflow: hidden;
  word-wrap: normal !important;
  white-space: normal;
}