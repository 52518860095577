.viewport {
    width:100vw;
    height:100vh;
    display:flex;
    flex-direction:column;
}
.main {
    width:100vw;
    height:86vh;
    background:white;
    display:flex;
    flex-direction:row;
    color:white;
}
.nav_button {
    width:8vw;
    height:86vh;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    border:1px solid white;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(8,39,120,1) 70%, rgba(0,20,255,1) 91%);
    font-size:48pt;
    cursor:pointer;
}
.nav_button_disabled {
    width:8vw;
    height:86vh;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    border:1px solid white;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(8,39,120,1) 70%, rgba(0,20,255,1) 91%);
    font-size:48pt;
}
.tile_row_holder {
    display:flex;
    flex-direction:column;
    width:84vw;
    height:43vh;
}
.tile_row {
    width: 84vw;
    height: 43vh;
    display:flex;
    flex-direction:row;
    background:blue;
}
.tile {
    width:28vw;
    height:43vh;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border:1px solid white;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(8,39,120,1) 70%, rgba(0,20,255,1) 91%);
    font-size:5vw;
}
.tile_notification {
    color:red;
    font-size:24pt;
}
.tile:hover {
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 20%, rgba(8,39,120,10) 60%, rgba(0,20,255,1) 91%);
    cursor:pointer;
}
.header_row {
    width:100vw;
    height:7vh;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(8,39,120,1) 70%, rgba(0,20,255,1) 91%);
    font-size:14pt;
    border:1px solid white;
    color:white;
    display:flex;
    padding:2px 5px 0 10px;
    align-items:center;
}
.footer_row {
    width:100vw;
    height:7vh;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(8,39,120,1) 70%, rgba(0,20,255,1) 91%);
    font-size:12pt;
    border:1px solid white;
    color:white;
    padding:1px 5px 0 10px;
    display:flex;
}
.widget {
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.widget_selected {
    background:red;
}
.widget_form {
    font-size:10pt;
}
.widget_form input {
    width:8vw;
    font-size:14pt;
}
.widget_button {
    width:22vw;
    font-size:3vw;
    height:10vh;
}
.modal_button {
    background:red;
    color:white;
    cursor:pointer;
    border:0;
    width:100%;
    padding:5px;
    font-size:14pt;
}
.modal_button:hover {
    background: #414141;
}
.modal_footer {
    background:red;
    padding:0;
    display:flex;
    flex-direction:row;
    justify-content: stretch;
}
.modal_header {
    font-size:18pt;
    padding:5px 5px 5px 25px;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
}
.modal_close_button {
    width:150px;
    font-size:18pt;
    text-align:center;
    cursor:pointer;
    display:flex;
    padding-right:20px;
    justify-content: end;
}
.modal_close_button:hover {
    background: #414141;
}

.hidden {
    display:none;
}

@media only screen and (min-width: 750px) {
    .modal_button {
        background:red;
        color:white;
        cursor:pointer;
        border:0;
        width:100%;
        padding:5px;
        font-size:3vh;
    }
}

.weird_hidey_div {
    background:blue;
    position:absolute;
    height:30px;
    width:30px;
    margin-top:2px;
    margin-left:2px;
}
.weird_hidey_input {
    width:100%;
    height:100%;
    background:blue;
    border:0;
    color:blue;
}