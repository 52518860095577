.weight_widget_form_row {
    display:flex;
    flex-direction:row;
    justify-content: space-evenly;
}
.weight_widget_date_time {
    width:200px;
}
.widget_weight_div {
    font-size:4vh;
}