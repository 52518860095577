/* Text Styles */
.strike {
    text-decoration:line-through;
}
.bold {
    font-weight:bold;
}


/* Button Styles */
.button {
    border:none;
    cursor:pointer;
    padding:10px;
}

/* Display Styles */
.hidden {
    display:none;
}

/* Cursor Styles */
.pointer {
    cursor: pointer;
}


/* Background Color Styles */
.black {
    background: black;
}
.red {
    background: red;
}
.yellow {
    background: yellow;
}

/* Font Color Styles */
.font_white {
    color:white;
}
.font_red {
    color:red;
}

/* Font Size Styles */
.font_small {
    font-size:8pt;
}
.font_12 {
    font-size:12pt;
}
.font_14 {
    font-size:14pt;
}
.font_18 {
    font-size:18pt;
}

/* Positioning Styles */
.display_top_right {
    position:absolute;
    right:0;
    top:0;
}

/* Elements */
.container {
    padding: 0.01em 16px;
}
.table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    display: table
}
.table td, .table th {
    padding: 8px 8px;
    display: table-cell;
    text-align: left;
    vertical-align: top
}
.table th:first-child, .table td:first-child {
    padding-left: 16px
}
.hoverable tbody tr:hover, .ul.hoverable li:hover {
    background-color: #ccc
}
.input {
    padding: 8px;
    display: block;
    border: none;
    border-bottom: 1px solid #ccc;
    width: 100%
}
.select {
    padding: 9px 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid #ccc
}
.check {
    width: 24px;
    height: 24px;
    position: relative;
    top: 6px
}






/* Modal Components */
.modal_hidden {
    display:none;
}
.modal_shown {
    z-index:3;
    display:block;
    padding-top:60px;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    overflow:auto;
    background-color:rgb(0,0,0);
    background-color:rgba(0,0,0,0.4)
}
.modal_content {
    margin:auto;
    background-color:#fff;
    position:relative;
    padding:0;
    outline:0;
    max-width:450px;
    width:90vw;
}
.modal_footer {
    background:red;
    padding:5px;
    display:flex;
    flex-direction:row;
    justify-content: end;
}
.modal_button {
    background:black;
    color:white;
    cursor:pointer;
    border:0;
}
.modal_button:hover {
    background: #414141;
}
.modal_footer_button {
    /*background:none;*/
    border:none;
    cursor:pointer;
    padding:10px;
}
.modal_footer_button:hover {
    background: rgb(190, 190, 190);
}
.modal_footer_button:focus {
    border:1px solid red;
}
.modal_header {
    background:red;
    width:100%;
    height:50px;
    color:white;
    display:flex;
    align-items:center;
    justify-content: space-between;
    font-size:16pt;
    padding:0 15px 0 15px;
}
.modal_close_button {
    width:150px;
    font-size:18pt;
    text-align:center;
    cursor:pointer;
    display:flex;
    padding-right:20px;
    justify-content: end;
    background:none;
    border:none;
    color:white;
}
.modal_close_button:hover {
    background: #be0000;
}
.modal_body {
    padding:20px;
    font-size:18pt;
}
.modal_gray_button {
    border:none;
    cursor:pointer;
    padding:10px;
}
.modal_gray_button:hover {
    background: rgb(190, 190, 190);
}



.are_you_sure {
    color:red;
    border: 3px dashed #9d9d9d;
    padding:10px;
    margin:10px 30px 10px 30px;
    font-size:12pt;
}
