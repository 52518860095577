.language_link {
    color: #64aace;
    text-decoration: none;
}
.language_sub_item {
    margin-left:20px;
}
.numbered_list_item {
    margin-bottom: 10px;
}
.bullet_list {
    margin-bottom: 10px;
}
strong {
    color: #606060;
}
.main_body_div {
    width:95%;
    margin:0 auto;
}

.lang_nav_bar {
    background:black;
    height:48px;
    display:flex;
    align-items: center;
    width:100%;
}
.lang_nav_bar a {
    color:white;
    text-decoration: none;
    font-size:18pt;
    padding-left: 24px;
}
.lang_nav_bar a:hover {
    text-shadow: #9a730d 1px 1px 2px;
}




/* LOGOUT MODAL */
.logout_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.logout_modal_modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.logout_modal_buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.logout_modal_logout_button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.logout_modal_stay_button {
    background-color: #9a730d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}


/* LOGIN FORM */
.language_login_container {
    border: 2px solid black;
    margin: 20px auto;
    max-width: 600px;
    background-color: #fff;
    border-radius: 10px;
}
.language_login_title_row {
    display: flex;
    justify-content: center;
}
.language_login_form_container {
    text-align: center;
    margin-bottom: 20px;
}
.language_login_row {
    width:100%;
    display:flex;
    flex-direction:row;
    align-items: center;
    margin-top: 20px;
    text-align: center;
}
.language_login_row label {
    width:100px;
    justify-items: end;
    text-align: end;
}
.language_login_input {
    border: 1px solid #5d4508;
    padding: 5px;
    font-size: 1em;
    width:100%;
    margin-left:10px;
}
.language_login_submit {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    margin-left:90px;
}

.language_login_submit:hover {
    background-color: #5d4508; /* Slightly darker gold */
}

/* Language User Profile Holder */
.lang_profile_div {
    background:black;
    padding:20px;
    color:white;
}
.logout_link {
    text-decoration:none;
    color:white;
}
.firstname_span {
    padding-right:20px;
}
.hidden {
    display:none;
}


/* Table of Contents Styles */
.tocContainer {
    margin: 20px auto;
    max-width: 600px;
    background-color: #fff;
    border: 2px solid #9a730d; /* Dark gold */
    border-radius: 8px;
    padding: 10px;
}

.toggleButton {
    display: block;
    width: 100%;
    background-color: #9a730d;
    color: white;
    border: none;
    padding: 10px;
    font-size: 1em;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
}

.toggleButton:hover {
    background-color: #815e0b; /* Slightly darker gold */
}

.tocContent {
    margin-top: 10px;
}

.tocHeading {
    font-size: 1.5em;
    font-weight: bold;
    color: #624c0e; /* Gold shade for headings */
    margin-bottom: 10px;
}

.tocList {
    list-style: none;
    padding: 0;
}

.tocList > li {
    margin-bottom: 10px;
}

.chapter {
    font-size: 1.2em;
    font-weight: bold;
    color: #000;
}

.subChapter {
    font-size: 1em;
    margin-left: 20px;
    color: #333;
}

.subSubChapter {
    font-size: 0.9em;
    margin-left: 40px;
    color: #555;
}
.toc_clickable_item {
    cursor:pointer;
    color:green;
}
