/* Mobile-first styles */

.container {
    padding: 0;
    max-width: 600px;
    margin: auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #fff; /* White background for readability and printing */
    color: #000; /* Black text for high contrast */
    text-align: left;
}
.course_overview_table {
    width:1000px;
}
.course_overview_table_holder {
    max-width:600px;
    overflow-x: auto;
    margin: auto;
    border: 1px solid black;
    border-radius: 4px;
    padding:10px;
}
.course_overview_table_holder td {
    padding:5px;
    vertical-align: top;
}
.course_overview_table_header {
    background:gray;
}
.course_overview_table_header td {
    font-weight:bold;
    background: gray;
}



.main_heading {
    font-size: 1.8em;
    font-weight: bold;
    color: #9a730d; /* Darkened goldenrod for main headings */
    margin-bottom: 16px;
}

.sub_heading {
    font-size: 1.4em;
    font-weight: bold;
    color: #815e0b; /* Darkened deep gold for subheadings */
    margin-top: 24px;
    margin-bottom: 12px;
}

.section_heading {
    font-size: 1.1em;
    font-weight: bold;
    color: #624c0e; /* Darkened dark goldenrod for section headings */
    margin-top: 16px;
    margin-bottom: 8px;
    font-style: italic;
}

.paragraph {
    margin-bottom: 12px;
    font-size: 1em;
    color: #333; /* Dark gray for easier readability */
}
.indented_paragraph {
    margin-bottom: 12px;
    font-size: 1em;
    color: #333; /* Dark gray for easier readability */
    text-indent: 2em;

}

.example {
    background-color: #f4f4f4; /* Light gray background for examples */
    border-left: 4px solid #9a730d; /* Darkened goldenrod border for emphasis */
    padding: 8px 12px;
    font-style: italic;
    margin: 12px 0;
    color: #000; /* Black text */
}

.list {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 16px;
    color: #333; /* Dark gray text */
}

.list li {
    margin-bottom: 8px;
}

.closing {
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    margin-top: 24px;
    color: #9a730d; /* Darkened goldenrod for closing emphasis */
}

/* Larger screens */
@media (min-width: 768px) {
    .container {
        padding: 24px;
    }

    .main_heading {
        font-size: 2.4em;
    }

    .sub_heading {
        font-size: 1.8em;
    }

    .section_heading {
        font-size: 1.4em;
    }

    .paragraph,
    .example {
        font-size: 1.1em;
    }
}

.header_image {
    width: 100%;
    border: 1px solid #ccc; /* Light gray border for a subtle frame */
}
.example_image {
    width: 100%;
    max-width: 600px;
    margin: 16px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.red_underline {
    text-decoration: underline;
    text-decoration-color: red; /* Red underline */
    text-decoration-thickness: 2px; /* Optional: Adjust the thickness */
    text-decoration-skip-ink: none; /* Ensures underline does not skip descenders */
}




/* Single underline for subject with label */
.subject {
    text-decoration: underline; /* Single underline */
    text-decoration-thickness: 2px;
    text-decoration-color: blue; /* Optional: Change underline color */
    position: relative; /* Needed for pseudo-element positioning */
    display: inline-block; /* Prevent line breaks */
}

.subject::after {
    content: "S"; /* Adds the "subject" label */
    position: absolute;
    left: 50%; /* Center align beneath the text */
    transform: translateX(-50%); /* Adjust for centering */
    bottom: -1.2em; /* Adjust distance below the underline */
    font-size: 0.8em; /* Smaller font for the label */
    color: blue; /* Match underline color */
}

/* Double underline for verb with label */
.verb {
    text-decoration: underline;
    text-decoration-thickness: 2px; /* Thicker underline gives appearance of double underline */
    text-decoration-color: red;
    position: relative;
    display: inline-block; /* Prevent line breaks */
}

.verb::after {
    content: "V"; /* Adds the "verb" label */
    position: absolute;
    left: 50%; /* Center align beneath the text */
    transform: translateX(-50%); /* Adjust for centering */
    bottom: -1.2em; /* Adjust distance below the underline */
    font-size: 0.8em;
    color: red;
}
