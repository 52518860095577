.main_body_div {
    max-width: 2000px;
    margin-top: 46px;
}

.page_content_div {
    max-width: 800px;
}
.pulse_header {
    min-height:30px;
    padding:20px 30px 20px 30px;
    background:black;
    color:red;
    margin:0;
}
.pulse_main_body {
    max-width:2000px;
}
.heart {
    margin-left:10px;
    font-size:36pt;
}